import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './footer'; // Change this line to match the actual file name
// Import your Header component here if you have one
// import Header from './Header';

const Layout: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Add your Header component here if you have one */}
      {/* <Header /> */}
      <main className="flex-grow">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;