import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardFooter } from "../../../components/ui/Card";
import { Badge } from "../../../components/ui/Badge";
import { Button } from "../../../components/ui/Button";
import { AlertTriangleIcon } from "../../../utils/icons";
import { useEventDetail } from '../../../hooks/useEventDetail';
import { EventHeader } from './EventHeader';
import { EventLocationInfo } from './EventLocationInfo';
import { EventDateInfo } from './EventDateInfo';
import { EventDescription } from './EventDescription';
import { EventAttendees } from './EventAttendees';
import { Timestamp } from 'firebase/firestore';
import { getImageUrl } from '../../../utils/firebaseUtils';
import defaultEventImage from '../../../assets/images/default-event-image.png';

export const EventDetailPage: React.FC = () => {
  console.log('EventDetailPage: Component rendering');
  const { id } = useParams<{ id: string }>();
  console.log('EventDetailPage: Event ID from params:', id);
  const navigate = useNavigate();
  const { event, imageUrl: eventImageUrl, attendees, isLoading } = useEventDetail(id);
  const [imageUrl, setImageUrl] = useState<string>(defaultEventImage);

  console.log('EventDetailPage: useEventDetail hook result:', { event, eventImageUrl, attendees, isLoading });

  useEffect(() => {
    console.log('EventDetailPage: useEffect for image URL');
    if (eventImageUrl) {
      console.log('EventDetailPage: Fetching image URL:', eventImageUrl);
      getImageUrl(eventImageUrl)
        .then(url => {
          console.log('EventDetailPage: Image URL fetched successfully:', url);
          setImageUrl(url);
        })
        .catch((error) => {
          console.error('EventDetailPage: Error fetching image URL:', error);
          setImageUrl(defaultEventImage);
        });
    } else {
      console.log('EventDetailPage: No event image URL, using default');
      setImageUrl(defaultEventImage);
    }
  }, [eventImageUrl]);

  if (isLoading) {
    console.log('EventDetailPage: Still loading...');
    return <div>Loading...</div>;
  }

  if (!event) {
    console.log('EventDetailPage: Event not found');
    return <div>Event not found</div>;
  }

  console.log('EventDetailPage: Event data loaded:', event);

  const handleRegister = () => {
    const formatDate = (date: Timestamp | string | null | undefined): string => {
      if (date instanceof Timestamp) {
        return date.toDate().toLocaleDateString();
      }
      if (typeof date === 'string') {
        return date;
      }
      return 'Date not available';
    };

    navigate(`/event/${id}/register`, { 
      state: { 
        eventTitle: event.title,
        eventDate: event.isSingleDay 
          ? formatDate(event.date)
          : `${formatDate(event.fromDate)} - ${formatDate(event.toDate)}`,
        eventTime: event.isSingleDay ? `${event.startTime} - ${event.endTime}` : '',
        eventLocation: event.isOnline ? 'Online Event' : event.location,
        eventDescription: event.description,
        organizer: event.organizerId,
        isFree: event.isFree,
        price: event.price
      } 
    });
  };

  const getButtonText = () => {
    if (event.isFree) return 'Register';
    if (event.price !== undefined && event.price !== null) {
      const price = typeof event.price === 'string' ? parseFloat(event.price) : event.price;
      if (!isNaN(price)) {
        return `Pay $${price.toFixed(2)} and Register`;
      }
    }
    return 'Register';
  };

  console.log('EventDetailPage: Rendering component with data');

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-3xl mx-auto overflow-hidden">
        {event.isCancelled && (
          <div className="bg-red-500 text-white py-2 px-4 flex items-center justify-center">
            <AlertTriangleIcon className="w-5 h-5 mr-2" />
            <span className="text-sm font-semibold">Event Cancelled</span>
          </div>
        )}
        <EventHeader 
          title={event.title} 
          imageUrl={imageUrl} 
          onImageError={() => setImageUrl(defaultEventImage)}
        />
        {/* Add this new section for event details */}
        <div className="bg-purple-50 p-4 flex justify-center space-x-4">
          <Badge variant={event.isFree ? "secondary" : "default"} className="bg-purple-100 text-purple-800">
            {event.isFree ? 'Free' : 'Paid'}
          </Badge>
          <Badge variant="outline" className="border-purple-200 text-purple-800">
            {event.isOnline ? 'Online' : 'In-person'}
          </Badge>
        </div>
        <CardContent className="p-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <EventLocationInfo event={event} />
            <EventDateInfo event={event} />
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <EventDescription description={event.description} />
            <EventAttendees 
              attendees={attendees} 
              maxCapacity={event.maxCapacity} 
            />
          </div>
        </CardContent>
        <CardFooter className="bg-purple-50 p-6 flex flex-col sm:flex-row justify-between items-center">
          <div className="space-x-2 mb-4 sm:mb-0">
            {event.isCancelled && (
              <Badge variant="outline" className="border-red-500 bg-red-100 text-red-800">
                Cancelled
              </Badge>
            )}
          </div>
          <div className="flex flex-col items-end">
            <Button 
              className="bg-purple-600 hover:bg-purple-700 text-white" 
              onClick={handleRegister}
              disabled={event.isCancelled}
            >
              {getButtonText()}
            </Button>
            {event.isCancelled && !event.isFree && (
              <p className="text-sm text-gray-600 mt-2">
                The amount will be refunded to your account within 5-7 working days.
              </p>
            )}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
