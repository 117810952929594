import React, { useEffect, useState } from 'react';
import { BookmarkIcon, AlertCircle } from 'lucide-react';
import { Input } from "../Input";
import { Button } from "../Button";
import { FilterBadge } from './FilterBadge';
import { DateDialog } from './DateDialog';
import { MultiSelectDialog } from './MultiSelectDialog';
import { useSearchContext } from '../../../contexts/SearchContext';
import { CATEGORIES, CITIES } from './constants';
import { formatDateRange } from '../../../utils/dateUtils';
import { useUserCity } from '../../../hooks/useUserCity';

export const SearchBar: React.FC = () => {
  const { state, setState, dialogState, setDialogState } = useSearchContext();
  const { city, loading: cityLoading, error: cityError } = useUserCity();
  const [tempLocations, setTempLocations] = useState<string[]>([]);
  const [tempCategories, setTempCategories] = useState<string[]>([]);
  const [userInteractedWithLocation, setUserInteractedWithLocation] = useState(false);
  const [userInteractedWithCategory, setUserInteractedWithCategory] = useState(false);

  useEffect(() => {
    if (city && !userInteractedWithLocation && state.selectedLocations.length === 0) {
      setState(prev => ({
        ...prev,
        selectedLocations: [city],
      }));
    }
  }, [city, state.selectedLocations, setState, userInteractedWithLocation]);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(prev => ({ ...prev, searchTerm: e.target.value }));
  };

  const handleBookmarkToggle = () => {
    setState(prev => ({ ...prev, showBookmarked: !prev.showBookmarked }));
  };

  const handleDateSelect = (dateRange: { start: Date; end: Date }) => {
    setState(prev => ({ ...prev, dateRange }));
    setDialogState(prev => ({ ...prev, isDateDialogOpen: false }));
  };

  const clearFilter = (filterType: 'date' | 'category' | 'price' | 'location') => {
    if (filterType === 'date') {
      setState(prev => ({ ...prev, dateRange: undefined }));
    } else {
      const key = `selected${filterType.charAt(0).toUpperCase() + filterType.slice(1)}s` as keyof typeof state;
      setState(prev => ({ ...prev, [key]: [] }));
    }
    if (filterType === 'location') {
      setUserInteractedWithLocation(true);
      setTempLocations([]);
    }
    if (filterType === 'category') {
      setUserInteractedWithCategory(true);
      setTempCategories([]);
      setState(prev => ({ ...prev, selectedCategories: [] }));
    }
  };

  const handleLocationChange = (values: string[]) => {
    setTempLocations(values);
  };

  const handleCategoryChange = (values: string[]) => {
    setTempCategories(values);
  };

  const handleLocationDialogClose = (confirmed: boolean) => {
    setUserInteractedWithLocation(true);
    if (confirmed) {
      setState(prev => ({
        ...prev,
        selectedLocations: tempLocations,
        isLocationChanged: true,
      }));
    } else {
      setTempLocations(state.selectedLocations);
    }
    setDialogState(prev => ({ ...prev, isLocationDialogOpen: false }));
  };

  const handleCategoryDialogClose = (confirmed: boolean) => {
    setUserInteractedWithCategory(true);
    if (confirmed) {
      setState(prev => ({
        ...prev,
        selectedCategories: tempCategories,
      }));
    } else {
      setTempCategories(state.selectedCategories);
    }
    setDialogState(prev => ({ ...prev, isCategoryDialogOpen: false }));
  };

  return (
    <div className="w-full">
      <div className="w-full bg-white shadow-md rounded-lg">
        <div className="p-4">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="flex items-center mb-4">
              <Input
                type="text"
                placeholder="Search events..."
                value={state.searchTerm}
                onChange={handleSearchTermChange}
                className="flex-grow mr-2 border-purple-200 focus:border-purple-400 focus:ring-purple-400"
              />
              <Button
                type="button"
                onClick={handleBookmarkToggle}
                className={`flex items-center justify-center transition-colors duration-300 ${
                  state.showBookmarked 
                    ? 'bg-purple-600 text-white' 
                    : 'bg-purple-100 text-purple-600 hover:bg-purple-200'
                }`}
                title={state.showBookmarked ? "Hide bookmarked events" : "Show bookmarked events"}
              >
                <BookmarkIcon className={`w-5 h-5 ${state.showBookmarked ? 'fill-current' : 'stroke-current'}`} />
                <span className="sr-only">{state.showBookmarked ? 'Hide bookmarked events' : 'Show bookmarked events'}</span>
              </Button>
            </div>
            <div className="flex flex-wrap justify-center gap-2 mb-4">
              <FilterBadge
                label="Date"
                value={state.dateRange ? formatDateRange(state.dateRange) : ''}
                onClick={() => setDialogState(prev => ({ ...prev, isDateDialogOpen: true }))}
                onClear={() => clearFilter('date')}
                isActive={!!state.dateRange}
              />
              <FilterBadge
                label="Category"
                value={state.selectedCategories.join(', ')}
                onClick={() => setDialogState(prev => ({ ...prev, isCategoryDialogOpen: true }))}
                onClear={() => clearFilter('category')}
                isActive={state.selectedCategories.length > 0}
              />
              <FilterBadge
                label="Price"
                value={state.selectedPrices.join(', ')}
                onClick={() => setDialogState(prev => ({ ...prev, isPriceDialogOpen: true }))}
                onClear={() => clearFilter('price')}
                isActive={state.selectedPrices.length > 0}
              />
              <FilterBadge
                label="Location"
                value={state.selectedLocations.join(', ')}
                onClick={() => setDialogState(prev => ({ ...prev, isLocationDialogOpen: true }))}
                onClear={() => clearFilter('location')}
                isActive={state.selectedLocations.length > 0}
                loading={cityLoading}
                error={cityError}
              />
            </div>
            {cityError && (
              <div className="text-red-500 flex items-center mb-4">
                <AlertCircle className="w-5 h-5 mr-2" />
                <span>{cityError}</span>
              </div>
            )}
            <DateDialog
              isOpen={dialogState.isDateDialogOpen}
              onOpenChange={(open) => setDialogState(prev => ({ ...prev, isDateDialogOpen: open }))}
              onDateSelect={handleDateSelect}
            />
            <MultiSelectDialog
              isOpen={dialogState.isCategoryDialogOpen}
              onOpenChange={(open) => {
                if (!open) {
                  handleCategoryDialogClose(false);
                } else {
                  setDialogState(prev => ({ ...prev, isCategoryDialogOpen: open }));
                  setTempCategories(state.selectedCategories);
                }
              }}
              title="Select Categories"
              options={CATEGORIES}
              selectedValues={tempCategories}
              onChange={handleCategoryChange}
              onConfirm={() => handleCategoryDialogClose(true)}
            />
            <MultiSelectDialog
              isOpen={dialogState.isPriceDialogOpen}
              onOpenChange={(open) => setDialogState(prev => ({ ...prev, isPriceDialogOpen: open }))}
              title="Select Price Ranges"
              options={['free', 'paid']}
              selectedValues={state.selectedPrices}
              onChange={(values) => {
                setState(prev => ({ ...prev, selectedPrices: values }));
              }}
            />
            <MultiSelectDialog
              isOpen={dialogState.isLocationDialogOpen}
              onOpenChange={(open) => {
                if (!open) {
                  handleLocationDialogClose(false);
                } else {
                  setDialogState(prev => ({ ...prev, isLocationDialogOpen: open }));
                  setTempLocations(state.selectedLocations);
                }
              }}
              title="Select Locations"
              options={CITIES}
              selectedValues={tempLocations}
              onChange={handleLocationChange}
              defaultValue={city || undefined}
              onConfirm={() => handleLocationDialogClose(true)}
              error={cityError}
            />
          </form>
        </div>
      </div>
    </div>
  );
};