import React, { useState, useEffect } from 'react';
import { format, isValid } from 'date-fns';
import { CalendarIcon, MapPinIcon, UsersIcon, RepeatIcon, GlobeIcon, ClockIcon, BookmarkIcon, AlertTriangleIcon } from "../utils/icons";
import { Event } from '../types/Event';
import { getImageUrl } from '../utils/firebaseUtils';
import { Timestamp } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import defaultEventImage from '../assets/images/default-event-image.png';

const formatDate = (timestamp: Timestamp | null | undefined) => {
  if (!timestamp) return 'Date not available';
  const date = timestamp.toDate();
  return isValid(date) ? format(date, "do MMMM yyyy") : 'Invalid date';
};

const formatDay = (timestamp: Timestamp | null | undefined) => {
  if (!timestamp) return 'Day not available';
  const date = timestamp.toDate();
  return isValid(date) ? format(date, 'EEEE') : 'Invalid date';
};

interface EventCardProps {
  event: Event;
  onBookmarkToggle: (eventId: string, isBookmarked: boolean) => void;
  isBookmarked: boolean;
  linkTo: string; // Change this from a function to a string
  userId: string; // Add this line
}

export const EventCard: React.FC<EventCardProps> = ({ event, onBookmarkToggle, isBookmarked, linkTo, userId }) => {
  const [imageUrl, setImageUrl] = React.useState<string>(defaultEventImage);

  const {
    title,
    date,
    location,
    locationLink,
    skill,
    imageUrl: eventImageUrl,
    fromDate,
    toDate,
    isRepeatedEvent,
    repeatedDaysOfWeek,
    isFree,
    startTime,
    endTime,
    isOnline,
    isCancelled,
  } = event;

  useEffect(() => {
    if (eventImageUrl) {
      getImageUrl(eventImageUrl)
        .then(url => setImageUrl(url))
        .catch(() => setImageUrl(defaultEventImage));
    }
  }, [eventImageUrl]);

  const handleBookmarkToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(`Toggling bookmark for event ${event.id}. Current state: ${isBookmarked}`);
    onBookmarkToggle(event.id, !isBookmarked);
  };

  const renderDateInfo = () => {
    if (isRepeatedEvent) {
      return (
        <div className="flex items-center text-purple-700">
          <RepeatIcon className="w-4 h-4 mr-2" />
          <span>Every {repeatedDaysOfWeek?.join(', ')}</span>
        </div>
      );
    } else if (fromDate && toDate && !fromDate.isEqual(toDate)) {
      return (
        <div className="flex items-center text-purple-700">
          <CalendarIcon className="w-4 h-4 mr-2" />
          <span>{formatDate(fromDate)} - {formatDate(toDate)}</span>
        </div>
      );
    } else if (date) {
      return (
        <div className="flex items-center text-purple-700">
          <CalendarIcon className="w-4 h-4 mr-2" />
          <span>{formatDay(date)}, {formatDate(date)}</span>
        </div>
      );
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="block">
        <Link to={linkTo} className="block">
          <div className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg h-full flex flex-col relative">
            {event.isCancelled && (
              <div className="absolute top-0 left-0 right-0 bg-red-500 text-white py-1 px-2 flex items-center justify-center z-10">
                <AlertTriangleIcon className="w-4 h-4 mr-2" />
                <span className="text-sm font-semibold">Event Cancelled</span>
              </div>
            )}
            <div className="relative flex-grow">
              <img
                src={imageUrl}
                alt={title}
                className={`w-full h-48 object-cover ${event.isCancelled ? 'opacity-50' : ''}`}
                onError={() => setImageUrl(defaultEventImage)}
              />
              <button
                className={`absolute top-2 right-2 p-2 rounded-full transition-colors duration-300 ${
                  isBookmarked 
                    ? 'bg-purple-500 text-white' 
                    : 'bg-white text-purple-500 hover:bg-purple-100'
                }`}
                onClick={handleBookmarkToggle}
              >
                <BookmarkIcon className={`w-6 h-6 ${isBookmarked ? 'fill-current' : 'stroke-current'}`} />
                <span className="sr-only">{isBookmarked ? 'Remove bookmark' : 'Bookmark event'}</span>
              </button>
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-purple-900 to-transparent p-4">
                <h3 className="text-xl font-bold text-white">{title}</h3>
              </div>
            </div>
            <div className="p-4 flex-grow">
              <div className="space-y-2 text-sm">
                {renderDateInfo()}
                <div className="flex items-center text-gray-600">
                  <ClockIcon className="w-4 h-4 mr-2" />
                  <span>{startTime} - {endTime}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  {isOnline ? (
                    <GlobeIcon className="w-4 h-4 mr-2" />
                  ) : (
                    <MapPinIcon className="w-4 h-4 mr-2" />
                  )}
                  <a href={locationLink} target="_blank" rel="noopener noreferrer" className="hover:underline">
                    {location}
                  </a>
                </div>
                <div className="flex items-center text-gray-600">
                  <UsersIcon className="w-4 h-4 mr-2" />
                  <span>{skill}</span>
                </div>
              </div>
            </div>
            <div className="bg-purple-50 p-4 mt-auto flex flex-wrap justify-between items-center gap-2">
              <span className={`px-2 py-1 rounded-full text-xs font-semibold ${isFree ? 'bg-purple-100 text-purple-800' : 'bg-purple-500 text-white'}`}>
                {isFree ? 'Free' : 'Paid'}
              </span>
              <span className="px-2 py-1 rounded-full text-xs font-semibold border border-purple-200 text-purple-800">
                {isOnline ? 'Online' : 'In-person'}
              </span>
              {event.isCancelled && (
                <span className="px-2 py-1 rounded-full text-xs font-semibold bg-red-100 text-red-800">
                  Cancelled
                </span>
              )}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default EventCard;