import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SearchProvider } from './contexts/SearchContext';
import { HeaderManager } from './components/HeaderManager';
import AppRoutes from './AppRoutes';
import { NotificationProvider } from './contexts/NotificationContext';

const App: React.FC = () => {
  return (
    <NotificationProvider>
      <SearchProvider>
        <Router>
          <HeaderManager />
          <AppRoutes />
        </Router>
      </SearchProvider>
    </NotificationProvider>
  );
};

export default App;