import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import { Card, CardContent } from '../components/ui/Card';
import { CalendarIcon, MapPinIcon, CreditCardIcon, UsersIcon, BookOpenIcon, MicIcon, MusicIcon, PaletteIcon, UtensilsIcon, ArrowRightIcon, CheckCircleIcon } from 'lucide-react';

const LandingPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('organizers');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSignUp = (e: React.FormEvent) => {
    e.preventDefault();
    navigate('/signup', { state: { email } });
  };

  const renderTabContent = (tab: string) => {
    const steps = tab === 'organizers'
      ? [
          { icon: CalendarIcon, text: "Create your event with ease - set details, pricing, and capacity." },
          { icon: MapPinIcon, text: "Add location details and sync with your calendar effortlessly." },
          { icon: CreditCardIcon, text: "Set up secure payment options for ticket sales." },
          { icon: UsersIcon, text: "Manage attendees, send updates, and track event success." }
        ]
      : [
          { icon: BookOpenIcon, text: "Discover local events that match your interests." },
          { icon: CreditCardIcon, text: "Register and pay securely using your preferred payment method." },
          { icon: MapPinIcon, text: "Get directions and add events to your calendar with one click." },
          { icon: CheckCircleIcon, text: "Enjoy the event and easily provide feedback afterwards." }
        ];

    return (
      <div className="mt-8 grid gap-6 md:grid-cols-2">
        {steps.map((step, index) => (
          <Card key={index} className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
            <CardContent className="p-6 flex items-start space-x-4">
              <div className="flex-shrink-0 w-12 h-12 rounded-full bg-purple-500 text-white flex items-center justify-center">
                <step.icon className="w-6 h-6" />
              </div>
              <div>
                <h3 className="font-semibold text-lg mb-2">Step {index + 1}</h3>
                <p className="text-gray-600">{step.text}</p>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1">
        {/* Section 1: Light lavender background, new blue heading text */}
        <section className="w-full py-20 md:py-32 bg-purple-100 text-[#3498db]">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <div className="relative">
              <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl mb-6 relative z-10">
                Simplify Event Management Globally
              </h1>
            </div>
            <p className="mx-auto max-w-[700px] text-xl mb-10 text-gray-600">
              From cooking classes to music lessons, EventAtEase makes organizing and attending local events a breeze.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <Link to="/organizer/create-event">
                <Button variant="primary" className="w-full sm:w-auto">Create an Event</Button>
              </Link>
              <Link to="/attendee/home">
                <Button variant="outline" className="w-full sm:w-auto">Discover Events</Button>
              </Link>
            </div>
          </div>
        </section>

        {/* Section 2: White background, rgb(124, 58, 237) color */}
        <section className="w-full py-20 bg-white">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-12 text-[rgb(124,58,237)]">
              Why Choose EventAtEase?
            </h2>
            <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-4">
              {[
                { icon: UsersIcon, title: "Elegant Marketplace", description: "Connect organizers with attendees effortlessly." },
                { icon: MapPinIcon, title: "Easy Location Access", description: "Find and share event locations with integrated maps." },
                { icon: CalendarIcon, title: "Calendar Integration", description: "Sync events with your personal calendar instantly." },
                { icon: CreditCardIcon, title: "Secure Payments", description: "Process payments safely and efficiently." },
              ].map((feature, index) => (
                <Card key={index} className="bg-purple-50 shadow-md hover:shadow-lg transition-shadow duration-300">
                  <CardContent className="p-6 flex flex-col items-center text-center">
                    <feature.icon className="h-12 w-12 text-[rgb(124,58,237)] mb-4" />
                    <h3 className="text-xl font-semibold mb-2 text-[rgb(124,58,237)]">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Section 3: Light lavender background */}
        <section className="w-full py-20 bg-purple-100">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl mb-8">
              How It Works
            </h2>
            <div className="w-full max-w-3xl mx-auto">
              <div className="flex justify-center mb-4">
                <button
                  className={`px-4 py-2 ${activeTab === 'organizers' ? 'bg-purple-500 text-white' : 'bg-white text-purple-500'} rounded-l-md`}
                  onClick={() => setActiveTab('organizers')}
                >
                  For Organizers
                </button>
                <button
                  className={`px-4 py-2 ${activeTab === 'attendees' ? 'bg-purple-500 text-white' : 'bg-white text-purple-500'} rounded-r-md`}
                  onClick={() => setActiveTab('attendees')}
                >
                  For Attendees
                </button>
              </div>
              {renderTabContent(activeTab)}
            </div>
          </div>
        </section>

        {/* Section 4: White background */}
        <section className="w-full py-20 bg-white">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-12 text-[rgb(124,58,237)]">
              Popular Event Types
            </h2>
            <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {[
                { icon: UtensilsIcon, title: "Cooking Workshops" },
                { icon: MusicIcon, title: "Music Lessons" },
                { icon: MicIcon, title: "Karaoke Nights" },
                { icon: UsersIcon, title: "Community Meetups" },
                { icon: PaletteIcon, title: "Art Classes" },
                { icon: BookOpenIcon, title: "Book Clubs" },
              ].map((item, index) => (
                <Card key={index} className="bg-purple-50 shadow-md hover:shadow-lg transition-shadow duration-300">
                  <CardContent className="p-6 flex items-center space-x-4">
                    <item.icon className="h-10 w-10 text-[rgb(124,58,237)]" />
                    <span className="text-lg font-semibold text-[rgb(124,58,237)]">{item.title}</span>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Section 5: Light lavender background */}
        <section className="w-full py-20 bg-purple-100">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-6 text-[rgb(124,58,237)]">
              Ready to simplify your event management?
            </h2>
            <p className="mx-auto max-w-[600px] text-xl mb-10 text-gray-600">
              Join EventAtEase today and start creating memorable experiences for your community.
            </p>
            <form className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4" onSubmit={handleSignUp}>
              <Input
                className="max-w-sm flex-1 bg-white text-[rgb(124,58,237)] placeholder-purple-300"
                placeholder="Enter your email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Button variant="default" className="bg-[rgb(124,58,237)] text-white hover:bg-[rgb(144,78,257)]">
                Sign Up <ArrowRightIcon className="ml-2 h-5 w-5" />
              </Button>
            </form>
            <p className="mt-4 text-sm text-gray-600">
              By signing up, you agree to our{" "}
              <Link className="underline underline-offset-2 hover:text-[rgb(124,58,237)]" to="#">
                Terms & Conditions
              </Link>
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LandingPage;