import React from 'react';
import { Card, CardContent } from "../../../components/ui/Card";
import { CalendarIcon } from "lucide-react";
import { Event } from '../../../models/Event';
import { formatDate, formatDay } from '../../../utils/dateUtils';
import { Timestamp } from 'firebase/firestore';

interface EventDateInfoProps {
  event: Event;
}

export const EventDateInfo: React.FC<EventDateInfoProps> = ({ event }) => {
  let dateContent: React.ReactNode = null;

  if (event.isRepeatedEvent) {
    dateContent = <p className="text-sm text-gray-600">Every {event.repeatedDaysOfWeek?.join(', ')}</p>;
  } else if (event.isSingleDay && event.date) {
    const eventDate = event.date.toDate();
    dateContent = (
      <>
        <p className="text-sm text-gray-600">{formatDay(eventDate)}, {formatDate(eventDate)}</p>
      </>
    );
  } else if (event.fromDate && event.toDate) {
    dateContent = (
      <>
        <p className="text-sm text-gray-600">From: {formatDate(event.fromDate.toDate())}</p>
        <p className="text-sm text-gray-600 mt-1">To: {formatDate(event.toDate.toDate())}</p>
      </>
    );
  }

  return dateContent ? (
    <Card className="h-full">
      <CardContent className="p-4">
        <div className="flex items-center mb-2">
          <CalendarIcon className="w-6 h-6 text-purple-600 mr-2" />
          <h3 className="text-lg font-semibold text-purple-800">Date</h3>
        </div>
        {dateContent}
      </CardContent>
    </Card>
  ) : null;
};