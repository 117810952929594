import React, { useState, useEffect, useCallback } from 'react';
import { EventsList } from '../../components/EventList';
import { Event as EventType } from '../../types/Event';
import { collection, query, where, getDocs, Timestamp, doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { Greeting } from '../../components/Greeting';
import { useSearchContext } from '../../contexts/SearchContext';
import { SearchBar } from '../../components/ui/SearchBar/SearchBar';

export const AttendeeHomePage: React.FC = () => {
  const [events, setEvents] = useState<EventType[]>([]);
  const [loading, setLoading] = useState(false);
  const { state: searchCriteria } = useSearchContext();
  const { user } = useAuth();

  const fetchEvents = useCallback(async () => {
    setLoading(true);
    try {
      const eventsRef = collection(db, 'events');
      const now = Timestamp.now();
      let singleDayQuery = query(
        eventsRef,
        where('isPublic', '==', true),
        where('isSingleDay', '==', true),
        where('date', '>=', now)
      );

      let multiDayQuery = query(
        eventsRef,
        where('isPublic', '==', true),
        where('isSingleDay', '==', false),
        where('toDate', '>=', now)
      );

      // Apply category filter
      if (searchCriteria.selectedCategories.length > 0) {
        singleDayQuery = query(singleDayQuery, where('categories', 'array-contains-any', searchCriteria.selectedCategories));
        multiDayQuery = query(multiDayQuery, where('categories', 'array-contains-any', searchCriteria.selectedCategories));
      }

      // Apply price filter
      if (searchCriteria.selectedPrices.length > 0) {
        if (searchCriteria.selectedPrices.includes('free') && !searchCriteria.selectedPrices.includes('paid')) {
          singleDayQuery = query(singleDayQuery, where('isFree', '==', true));
          multiDayQuery = query(multiDayQuery, where('isFree', '==', true));
        } else if (!searchCriteria.selectedPrices.includes('free') && searchCriteria.selectedPrices.includes('paid')) {
          singleDayQuery = query(singleDayQuery, where('isFree', '==', false));
          multiDayQuery = query(multiDayQuery, where('isFree', '==', false));
        }
      }

      // Apply location filter
      if (searchCriteria.selectedLocations.length > 0) {
        const locationChunks = [];
        for (let i = 0; i < searchCriteria.selectedLocations.length; i += 10) {
          locationChunks.push(searchCriteria.selectedLocations.slice(i, i + 10));
        }

        const singleDaySnapshots = await Promise.all(
          locationChunks.map(chunk => getDocs(query(singleDayQuery, where('location', 'in', chunk))))
        );
        const multiDaySnapshots = await Promise.all(
          locationChunks.map(chunk => getDocs(query(multiDayQuery, where('location', 'in', chunk))))
        );

        const singleDayEvents = singleDaySnapshots.flatMap(snapshot => snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }) as EventType));

        const multiDayEvents = multiDaySnapshots.flatMap(snapshot => snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }) as EventType));

        let finalEvents = [...singleDayEvents, ...multiDayEvents];

        // Apply date range filter if applicable
        if (searchCriteria.dateRange) {
          const { start, end } = searchCriteria.dateRange;
          finalEvents = finalEvents.filter(event => {
            const eventDate = event.isSingleDay ? event.date?.toDate() : event.fromDate?.toDate();
            return eventDate && eventDate >= start && eventDate <= end;
          });
        }

        // Handle bookmarked events
        if (searchCriteria.showBookmarked && user) {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.data();
          const bookmarkedEventIds: string[] = userData?.bookmarkedEvents || [];
          finalEvents = finalEvents.filter(event => bookmarkedEventIds.includes(event.id));
        }

        setEvents(finalEvents);
      } else {
        // If no location filter is applied
        const [singleDaySnapshot, multiDaySnapshot] = await Promise.all([
          getDocs(singleDayQuery),
          getDocs(multiDayQuery),
        ]);

        const singleDayEvents = singleDaySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }) as EventType);

        const multiDayEvents = multiDaySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }) as EventType);

        let finalEvents = [...singleDayEvents, ...multiDayEvents];

        // Apply date range filter if applicable
        if (searchCriteria.dateRange) {
          const { start, end } = searchCriteria.dateRange;
          finalEvents = finalEvents.filter(event => {
            const eventDate = event.isSingleDay ? event.date?.toDate() : event.fromDate?.toDate();
            return eventDate && eventDate >= start && eventDate <= end;
          });
        }

        // Handle bookmarked events
        if (searchCriteria.showBookmarked && user) {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.data();
          const bookmarkedEventIds: string[] = userData?.bookmarkedEvents || [];
          finalEvents = finalEvents.filter(event => bookmarkedEventIds.includes(event.id));
        }

        setEvents(finalEvents);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  }, [searchCriteria, user]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  return (
    <div className="container mx-auto px-4 py-8">
      <Greeting />
      <div className="mb-8"> {/* Add this wrapper div with margin-bottom */}
        <SearchBar />
      </div>
      {loading ? (
        <p>Loading events...</p>
      ) : events.length > 0 ? (
        <EventsList 
          events={events} 
          linkTo={(eventId: string) => `/event/${eventId}`}
          userId={user?.uid || ''}
        />
      ) : (
        <p>No upcoming events found.</p>
      )}
    </div>
  );
};
