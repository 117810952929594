import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Event } from '../../types/Event';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from "../../components/ui/Button";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/Card";
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytesResumable, getDownloadURL, uploadString } from 'firebase/storage';
import { storage } from '../../config/firebase';
import { FirebaseError } from 'firebase/app';
import { Timestamp, addDoc, collection, updateDoc, doc, arrayUnion, setDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../../config/firebase'; // Ensure this import is correct

import { EventBasicInfo } from '../EventBasicInfo';
import { EventTypeSelection } from '../EventTypeSelection';
import { EventVisibilitySelection } from '../EventVisibilitySelection';
import { EventLocationInput } from '../EventLocationInput';
import { EventDurationSelection } from '../EventDurationSelection';
import { EventDateTimeInputs } from '../EventDateTimeInputs';
import { EventFrequencySelection } from '../EventFrequencySelection';
import { EventPriceSelection } from '../EventPriceSelection';
import { EventSkillInput } from '../EventSkillInput';
import { EventImageUpload } from '../EventImageUpload';
import { useEventValidation } from '../../hooks/useEventValidation';
import { useModalMessage } from '../../hooks/useModalMessage';
import { ConfirmationModal } from '../common/ConfirmationModal';

// Update Event type to include googleCalendarEventId
type UpdatedEvent = Event & { googleCalendarEventId?: string; imageData?: string };

export const CreateEventForm: React.FC = () => {
  const [event, setEvent] = useState<Partial<UpdatedEvent>>({
    title: '',
    description: '',
    isOnline: false,
    isSingleDay: true,
    isRepeatedEvent: false,
    isFree: true,
    skill: '',
    isPublic: true,
    date: null,
    status: 'upcoming',
    attendeeCount: 0,
    attendees: [],
    tags: [],
    categories: [],
    maxCapacity: 100,
    feedback: [],
    imageData: undefined, // Add this line
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { validateEvent } = useEventValidation();
  const { modalMessage, isModalOpen, showModal, closeModal } = useModalMessage();
  const { user: currentUser } = useAuth();

  const handleChange = (field: string, value: any) => {
    if (field === 'imageFile') {
      setImageFile(value);
    } else {
      setEvent((prevEvent) => ({ ...prevEvent, [field]: value }));
    }
  };

  const handleImageUpload = (fileOrData: File | string) => {
    if (typeof fileOrData === 'string') {
      // Handle base64 image data
      setEvent(prevEvent => ({ ...prevEvent, imageData: fileOrData }));
    } else {
      // Handle File object
      setImageFile(fileOrData);
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      
      if (!currentUser) {
        showModal('You must be logged in to create an event.');
        return;
      }

      const eventId = uuidv4();
      let imageUrl = '';

      if (imageFile) {
        imageUrl = await uploadImage(imageFile);
      } else if (event.imageData) {
        // Upload base64 image data
        imageUrl = await uploadImage(event.imageData);
      }

      const now = Timestamp.now();

      const newEvent: Partial<UpdatedEvent> = {
        id: eventId,
        urlFriendlyId: eventId,
        title: event.title || '',
        description: event.description || '',
        isOnline: event.isOnline || false,
        isSingleDay: event.isSingleDay || true,
        date: event.date || now,
        isRepeatedEvent: event.isRepeatedEvent || false,
        isFree: event.isFree || true,
        skill: event.skill || '',
        isPublic: event.isPublic || true,
        status: 'upcoming',
        attendeeCount: 0,
        attendees: [],
        organizerId: currentUser.uid,
        createdAt: now,
        updatedAt: now,
        tags: [],
        categories: [],
        maxCapacity: event.maxCapacity || 100,
        feedback: [],
        imageUrl,
        isCancelled: false,
        startTime: event.startTime || now.toDate().toISOString(),
        endTime: event.endTime || now.toDate().toISOString(),
      };

      if (event.location) newEvent.location = event.location;
      if (event.locationLink) newEvent.locationLink = event.locationLink;
      if (event.fromDate) newEvent.fromDate = event.fromDate;
      if (event.toDate) newEvent.toDate = event.toDate;
      if (event.repeatedDaysOfWeek) newEvent.repeatedDaysOfWeek = event.repeatedDaysOfWeek;
      if (event.price) newEvent.price = event.price;

      if (!validateEvent(newEvent)) {
        showModal('Invalid event data. Please check all fields.');
        return;
      }

      // Call the Firebase Function to create the event and get the Meet link
      const functions = getFunctions();
      const createEventFunction = httpsCallable<{ eventDetails: Partial<UpdatedEvent> }, { eventId: string; meetLink: string | null }>(functions, 'createEvent');
      
      const result = await createEventFunction({ 
        eventDetails: {
          id: newEvent.id,
          title: newEvent.title,
          description: newEvent.description,
          isOnline: newEvent.isOnline, // Make sure to include this
          startTime: newEvent.isSingleDay 
            ? newEvent.date?.toDate().toISOString() 
            : newEvent.fromDate?.toDate().toISOString(),
          endTime: newEvent.isSingleDay 
            ? newEvent.date?.toDate().toISOString() 
            : newEvent.toDate?.toDate().toISOString(),
        }
      });

      console.log("Event creation result:", result);

      // Update the event with the Meet link and Google Calendar Event ID
      if (result.data.meetLink) {
        newEvent.meetingLink = result.data.meetLink;
      }
      if (result.data.eventId) {
        newEvent.googleCalendarEventId = result.data.eventId;
      }

      // Remove any undefined fields before adding to Firestore
      const eventToAdd: Partial<UpdatedEvent> = Object.entries(newEvent).reduce((acc, [key, value]) => {
        if (value !== undefined) {
          (acc as any)[key] = value;
        }
        return acc;
      }, {} as Partial<UpdatedEvent>);

      // Use setDoc instead of addDoc
      await setDoc(doc(db, 'events', eventId), eventToAdd);

      // Update the user's eventsCreated array
      await updateDoc(doc(db, 'users', currentUser.uid), {
        eventsCreated: arrayUnion(eventId)
      });
      
      showModal('Event created successfully!');
      navigate('/organizer/dashboard');
    } catch (error) {
      console.error("Detailed error in onSubmit:", error);
      if (error instanceof FirebaseError) {
        console.error("Firebase error details:", error.code, error.message);
        showModal(`Failed to create event: ${error.message}`);
      } else if (error instanceof Error) {
        showModal(`Failed to create event: ${error.message}`);
      } else {
        showModal('Failed to create event. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const uploadImage = async (fileOrData: File | string): Promise<string> => {
    const uniqueId = uuidv4();
    const storageRef = ref(storage, `images/${uniqueId}.jpg`);
    
    if (typeof fileOrData === 'string') {
      // It's base64 data
      await uploadString(storageRef, fileOrData, 'base64', { contentType: 'image/jpeg' });
    } else {
      // It's a File object
      await uploadBytesResumable(storageRef, fileOrData);
    }
    
    return getDownloadURL(storageRef);
  };

  return (
    <Card className="max-w-2xl mx-auto bg-purple-50">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-center text-purple-800">Create New Event</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={(e) => { e.preventDefault(); onSubmit(); }} className="space-y-6">
          <EventBasicInfo event={event} onChange={handleChange} />
          <EventTypeSelection event={event} onChange={handleChange} />
          <EventVisibilitySelection event={event} onChange={handleChange} />
          {!event.isOnline && <EventLocationInput event={event} onChange={handleChange} />}
          <EventDurationSelection event={event} onChange={handleChange} />
          <EventDateTimeInputs event={event} onChange={handleChange} />
          <EventFrequencySelection event={event} onChange={handleChange} />
          <EventPriceSelection event={event} onChange={handleChange} />
          <EventSkillInput event={event} onChange={handleChange} />
          <EventImageUpload 
            onUpload={handleImageUpload} 
            eventTitle={event.title || ''} 
            eventDescription={event.description || ''}
          />

          <Button type="submit" className="w-full bg-purple-600 hover:bg-purple-700 text-white" disabled={loading}>
            {loading ? 'Creating...' : 'Create Event'}
          </Button>
        </form>
      </CardContent>
      {isModalOpen && modalMessage && <ConfirmationModal message={modalMessage} onClose={closeModal} />}
    </Card>
  );
};
