import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, useLocation, Location } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { FcGoogle } from 'react-icons/fc';
import AnimatedBackground from '../../components/AnimatedBackground';

interface LocationState {
  from?: Location;
}

export const LoginPage: React.FC = () => {
  const { signInWithGoogle, error, isFirstTimeUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = (location.state as LocationState)?.from?.pathname || '/attendee/home';

  const handleGoogleSignIn = async () => {
    await signInWithGoogle();
    if (!error) {
      if (isFirstTimeUser) {
        navigate('/first-signin', { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    }
  };

  return (
    <>
      <AnimatedBackground />
      <div className="auth-container flex flex-col items-center justify-center min-h-screen relative z-10">
        <div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-md w-full max-w-md">
          <h2 className="text-2xl font-bold mb-6 text-center text-purple-800">Login</h2>
          <Button
            onClick={handleGoogleSignIn}
            icon={<FcGoogle className="text-xl" />}
            className="w-full mt-4 bg-purple-600 hover:bg-purple-700 text-white"
          >
            Sign in with Google
          </Button>
          {error && <p className="error mt-4 text-red-500">{error}</p>}
        </div>
      </div>
    </>
  );
};