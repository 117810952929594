import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "../components/ui/Card";
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/Avatar";
import { Button } from "../components/ui/Button";
import { Input } from "../components/ui/Input";
import { Label } from "../components/ui/Label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/Select";
import { Switch } from "../components/ui/Switch";
import { CameraIcon, CheckCircleIcon } from "lucide-react";
import { auth, db } from '../config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { User } from '../models/User';
import { Timestamp } from 'firebase/firestore';

interface UserProfile {
  id: string;
  name: string;
  email: string;
  gender: string;
  dateOfBirth: string;
  eventsAttended: string[];
  eventsCreated: string[];
  bankAccountDetails: {
    accountNumber: string;
    bankName: string;
    ifscCode: string;
    isVerified: boolean;
  };
  upiDetails: string;
  upiVerified: boolean;
  subscribers: string[];
  isOrganizer: boolean;
  profilePicture: string;
  phone: string;
  createdAt?: Date;
  lastLogin?: Date;
  bookmarkedEvents: string[]; // Add this line
}

export const EditProfilePage: React.FC = () => {
  const [editedUser, setEditedUser] = useState<UserProfile | null>(null);
  const [activeSection, setActiveSection] = useState('personal');
  const [paymentMethod, setPaymentMethod] = useState<'upi' | 'bank'>('upi');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data() as Omit<UserProfile, 'dateOfBirth'> & { dateOfBirth: string | Date | { seconds: number; nanoseconds: number } };
            let formattedDateOfBirth = '';

            if (userData.dateOfBirth) {
              if (userData.dateOfBirth instanceof Date) {
                formattedDateOfBirth = userData.dateOfBirth.toISOString().split('T')[0];
              } else if (typeof userData.dateOfBirth === 'string') {
                formattedDateOfBirth = new Date(userData.dateOfBirth).toISOString().split('T')[0];
              } else if (typeof userData.dateOfBirth === 'object' && 'seconds' in userData.dateOfBirth) {
                formattedDateOfBirth = new Date(userData.dateOfBirth.seconds * 1000).toISOString().split('T')[0];
              }
            }

            setEditedUser({
              id: currentUser.uid,
              name: currentUser.displayName || '',
              email: currentUser.email || '',
              gender: userData.gender || '',
              dateOfBirth: formattedDateOfBirth,
              eventsAttended: userData.eventsAttended || [],
              eventsCreated: userData.eventsCreated || [],
              bankAccountDetails: userData.bankAccountDetails || { accountNumber: '', bankName: '', ifscCode: '', isVerified: false },
              upiDetails: userData.upiDetails || '',
              upiVerified: userData.upiVerified || false,
              subscribers: userData.subscribers || [],
              isOrganizer: userData.isOrganizer || false,
              profilePicture: userData.profilePicture || '',
              phone: userData.phone || '',
              createdAt: userData.createdAt instanceof Timestamp ? userData.createdAt.toDate() : new Date(),
              lastLogin: userData.lastLogin instanceof Timestamp ? userData.lastLogin.toDate() : new Date(),
              bookmarkedEvents: userData.bookmarkedEvents || [], // Add this line
            });
          } else {
            console.log("No such user document!");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedUser(prev => prev ? ({ ...prev, [name]: value }) : null);
  };

  const handleBankDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedUser(prev => prev ? ({
      ...prev,
      bankAccountDetails: { ...prev.bankAccountDetails, [name]: value }
    }) : null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (editedUser && auth.currentUser) {
      try {
        const userToUpdate = new User(
          auth.currentUser.uid,
          editedUser.gender,
          new Date(editedUser.dateOfBirth),
          editedUser.eventsAttended,
          editedUser.eventsCreated,
          editedUser.bankAccountDetails,
          editedUser.upiDetails,
          editedUser.upiVerified,
          editedUser.subscribers,
          editedUser.isOrganizer,
          editedUser.profilePicture,
          editedUser.phone,
          editedUser.createdAt instanceof Date ? editedUser.createdAt : new Date(editedUser.createdAt || Date.now()),
          new Date(), // Update lastLogin to current date
          editedUser.bookmarkedEvents || [] // Add this line
        );

        await userToUpdate.save();

        console.log('User profile updated successfully');
        navigate('/profile');
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    }
  };

  const handleVerifyUPI = () => {
    setEditedUser(prev => prev ? ({ ...prev, upiVerified: true }) : null);
  };

  const handleVerifyBank = () => {
    setEditedUser(prev => prev ? ({
      ...prev,
      bankAccountDetails: { ...prev.bankAccountDetails, isVerified: true }
    }) : null);
  };

  if (!editedUser) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-purple-800">Edit Profile</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex space-x-2 mb-6">
            <Button
              variant={activeSection === 'personal' ? 'default' : 'outline'}
              onClick={() => setActiveSection('personal')}
            >
              Personal Information
            </Button>
            <Button
              variant={activeSection === 'account' ? 'default' : 'outline'}
              onClick={() => setActiveSection('account')}
            >
              Account Settings
            </Button>
            {editedUser.isOrganizer && (
              <Button
                variant={activeSection === 'financial' ? 'default' : 'outline'}
                onClick={() => setActiveSection('financial')}
              >
                Financial Information
              </Button>
            )}
          </div>

          <form onSubmit={handleSubmit}>
            {activeSection === 'personal' && (
              <div className="space-y-6">
                <div className="flex items-center space-x-4">
                  <Avatar className="w-24 h-24">
                    <AvatarImage src={editedUser.profilePicture} alt={editedUser.name} />
                    <AvatarFallback>{editedUser.name.charAt(0)}</AvatarFallback>
                  </Avatar>
                  <Button variant="outline">
                    <CameraIcon className="w-4 h-4 mr-2" />
                    Change Picture
                  </Button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="name">Name</Label>
                    <Input id="name" name="name" value={editedUser.name} onChange={handleInputChange} />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="email">Email</Label>
                    <Input id="email" name="email" type="email" value={editedUser.email} onChange={handleInputChange} />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="phone">Phone</Label>
                    <Input id="phone" name="phone" value={editedUser.phone} onChange={handleInputChange} />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="gender">Gender</Label>
                    <Select 
                      value={editedUser.gender} 
                      onValueChange={(value: string) => setEditedUser(prev => prev ? { ...prev, gender: value } : null)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select gender" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="male">Male</SelectItem>
                        <SelectItem value="female">Female</SelectItem>
                        <SelectItem value="other">Other</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="dateOfBirth">Date of Birth</Label>
                    <Input 
                      id="dateOfBirth" 
                      name="dateOfBirth" 
                      type="date" 
                      value={editedUser.dateOfBirth} 
                      onChange={(e) => {
                        setEditedUser(prev => prev ? { ...prev, dateOfBirth: e.target.value } : null);
                      }} 
                    />
                  </div>
                </div>
              </div>
            )}

            {activeSection === 'account' && (
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <Switch
                    checked={editedUser.isOrganizer}
                    onCheckedChange={(checked: boolean) => setEditedUser(prev => prev ? { ...prev, isOrganizer: checked } : null)}
                  />
                  <Label htmlFor="isOrganizer">I want to organize events</Label>
                </div>
                <p className="text-sm text-gray-500">
                  Enabling this option will allow you to create and manage events on our platform.
                </p>
              </div>
            )}

            {activeSection === 'financial' && editedUser.isOrganizer && (
              <div className="space-y-4">
                <div className="flex items-center space-x-2 mb-4">
                  <Label htmlFor="paymentMethod">Payment Method:</Label>
                  <Select 
                    value={paymentMethod} 
                    onValueChange={(value: string) => setPaymentMethod(value as 'upi' | 'bank')}
                  >
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Select payment method" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="upi">UPI</SelectItem>
                      <SelectItem value="bank">Bank Account</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {paymentMethod === 'upi' && (
                  <div className="space-y-2">
                    <Label htmlFor="upiDetails">UPI ID</Label>
                    <div className="flex space-x-2">
                      <Input 
                        id="upiDetails" 
                        name="upiDetails" 
                        value={editedUser.upiDetails} 
                        onChange={handleInputChange} 
                      />
                      <Button 
                        onClick={handleVerifyUPI}
                        disabled={editedUser.upiVerified}
                        className={editedUser.upiVerified ? "bg-green-500" : "bg-purple-600 hover:bg-purple-700"}
                      >
                        {editedUser.upiVerified ? (
                          <>
                            <CheckCircleIcon className="w-4 h-4 mr-2" />
                            Verified
                          </>
                        ) : (
                          'Verify UPI'
                        )}
                      </Button>
                    </div>
                  </div>
                )}

                {paymentMethod === 'bank' && (
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="accountNumber">Account Number</Label>
                      <Input 
                        id="accountNumber" 
                        name="accountNumber" 
                        value={editedUser.bankAccountDetails.accountNumber} 
                        onChange={handleBankDetailsChange} 
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="bankName">Bank Name</Label>
                      <Input 
                        id="bankName" 
                        name="bankName" 
                        value={editedUser.bankAccountDetails.bankName} 
                        onChange={handleBankDetailsChange} 
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="ifscCode">IFSC Code</Label>
                      <Input 
                        id="ifscCode" 
                        name="ifscCode" 
                        value={editedUser.bankAccountDetails.ifscCode} 
                        onChange={handleBankDetailsChange} 
                      />
                    </div>
                    <Button 
                      onClick={handleVerifyBank}
                      disabled={editedUser.bankAccountDetails.isVerified}
                      className={editedUser.bankAccountDetails.isVerified ? "bg-green-500" : "bg-purple-600 hover:bg-purple-700"}
                    >
                      {editedUser.bankAccountDetails.isVerified ? (
                        <>
                          <CheckCircleIcon className="w-4 h-4 mr-2" />
                          Verified
                        </>
                      ) : (
                        'Verify Bank Account'
                      )}
                    </Button>
                  </div>
                )}
              </div>
            )}

            <CardFooter className="flex justify-end space-x-2 mt-6">
              <Button variant="outline" onClick={() => navigate('/profile')}>Cancel</Button>
              <Button type="submit" className="bg-purple-600 hover:bg-purple-700 text-white">Save Changes</Button>
            </CardFooter>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};