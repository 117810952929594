import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../Dialog';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { AlertCircle } from 'lucide-react';

interface MultiSelectDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
  options: string[];
  selectedValues: string[];
  onChange: (values: string[]) => void;
  defaultValue?: string;
  onConfirm?: () => void;
  error?: string | null;
}

export const MultiSelectDialog: React.FC<MultiSelectDialogProps> = ({
  isOpen,
  onOpenChange,
  title,
  options,
  selectedValues,
  onChange,
  defaultValue,
  onConfirm,
  error
}) => {
  const handleChange = (option: string) => {
    const newSelectedValues = selectedValues.includes(option)
      ? selectedValues.filter((value) => value !== option)
      : [...selectedValues, option];
    onChange(newSelectedValues);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {error && (
          <div className="text-red-500 mb-4 flex items-center">
            <AlertCircle className="w-5 h-5 mr-2" />
            <span>{error}</span>
          </div>
        )}
        <div className="space-y-2">
          {options.map((option) => (
            <div key={option} className="flex items-center">
              <Checkbox
                id={option}
                checked={selectedValues.includes(option)}
                onChange={() => handleChange(option)}
              />
              <label htmlFor={option} className="ml-2 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                {option}
              </label>
            </div>
          ))}
        </div>
        {onConfirm && (
          <Button onClick={onConfirm} className="mt-4">
            Confirm
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};