import React, { useState, useEffect } from 'react'
import { format, isValid, parseISO } from 'date-fns'
import { Card, CardContent, CardFooter } from "../../components/ui/Card"
import { Badge } from "../../components/ui/Badge"
import { Button } from "../../components/ui/Button"
import { Progress } from "../../components/ui/Progress"
import { CalendarIcon, MapPinIcon, ClockIcon, UsersIcon, RepeatIcon, GlobeIcon, IndianRupeeIcon, EditIcon, TrashIcon, BarChart2Icon, Send } from "lucide-react"
import { Event } from '../../models/Event'
import { useParams, Link } from 'react-router-dom'
import { getImageUrl } from '../../utils/firebaseUtils'
import defaultEventImage from '../../assets/images/default-event-image.png'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/Dialog"
import { useToast } from "../../hooks/useToast"
import { Timestamp } from 'firebase/firestore';

const formatDate = (timestamp: Timestamp | null | undefined) => {
  if (!timestamp) return 'Date not available';
  return isValid(timestamp.toDate()) ? format(timestamp.toDate(), 'do MMMM yyyy') : 'Invalid date';
};

const formatDay = (timestamp: Timestamp | null | undefined) => {
  if (!timestamp) return 'Day not available';
  return format(timestamp.toDate(), 'EEEE');
};

export const OrganizerEventDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [event, setEvent] = useState<Event | null>(null)
  const [imageUrl, setImageUrl] = useState<string>(defaultEventImage)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const { showToast } = useToast()

  useEffect(() => {
    const fetchEvent = async () => {
      if (id) {
        const fetchedEvent = await Event.findById(id)
        if (fetchedEvent) {
          setEvent(fetchedEvent)
          if (fetchedEvent.imageUrl) {
            try {
              const url = await getImageUrl(fetchedEvent.imageUrl)
              setImageUrl(url)
            } catch {
              setImageUrl(defaultEventImage)
            }
          }
        }
      }
    }
    fetchEvent()
  }, [id])

  const handleImageError = () => {
    setImageUrl(defaultEventImage)
  }

  if (!event) {
    return <div>Loading...</div>
  }

  const renderDateInfo = () => {
    console.log('Event date:', event.date);
    console.log('Event fromDate:', event.fromDate);
    console.log('Event toDate:', event.toDate);

    if (event.isRepeatedEvent) {
      return (
        <div className="flex items-center text-purple-700">
          <RepeatIcon className="w-6 h-6 mr-2" />
          <span>Every {event.repeatedDaysOfWeek?.join(', ')}</span>
        </div>
      )
    } else if (event.fromDate && event.toDate && !event.fromDate.isEqual(event.toDate)) {
      return (
        <div className="flex items-center text-purple-700">
          <CalendarIcon className="w-6 h-6 mr-2" />
          <span>{formatDate(event.fromDate)} - {formatDate(event.toDate)}</span>
        </div>
      )
    } else if (event.date) {
      return (
        <div className="flex items-center text-purple-700">
          <CalendarIcon className="w-6 h-6 mr-2" />
          <span>{formatDay(event.date)}, {formatDate(event.date)}</span>
        </div>
      )
    }
    return <div>No date information available</div>;
  }

  const attendeePercentage = (event.attendeeCount / event.maxCapacity) * 100

  const handleOpenCancelModal = () => {
    setIsCancelModalOpen(true)
  }

  const handleCloseCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const handleCancelEvent = async () => {
    if (event && event.id) {
      try {
        await Event.cancel(event.id);
        showToast({
          title: "Event Cancelled",
          description: "The event has been successfully cancelled.",
          variant: "default",
        });
        // Update the local event state
        setEvent(prevEvent => {
          if (prevEvent) {
            return new Event(
              prevEvent.id,
              prevEvent.urlFriendlyId,
              prevEvent.title,
              prevEvent.description,
              prevEvent.isOnline,
              prevEvent.location,
              prevEvent.locationLink,
              prevEvent.isSingleDay,
              prevEvent.date,
              prevEvent.startTime,
              prevEvent.endTime,
              prevEvent.fromDate,
              prevEvent.toDate,
              prevEvent.isRepeatedEvent,
              prevEvent.repeatedDaysOfWeek,
              prevEvent.isFree,
              prevEvent.price,
              prevEvent.imageUrl,
              prevEvent.meetingLink,
              prevEvent.skill,
              prevEvent.isPublic,
              'cancelled',
              prevEvent.attendeeCount,
              prevEvent.attendees,
              prevEvent.organizerId,
              prevEvent.createdAt,
              prevEvent.updatedAt,
              prevEvent.tags,
              prevEvent.categories,
              prevEvent.maxCapacity,
              prevEvent.feedback,
              true // isCancelled
            );
          }
          return null;
        });
        handleCloseCancelModal();
      } catch (error) {
        console.error("Error cancelling event:", error);
        showToast({
          title: "Error",
          description: "Failed to cancel the event. Please try again.",
          variant: "destructive",
        });
      }
    }
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-4xl mx-auto overflow-hidden">
        <div className="relative">
          <img 
            src={imageUrl} 
            alt={event?.title} 
            className="w-full h-64 object-cover" 
            onError={handleImageError}
          />
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-purple-900 to-transparent p-6">
            <h1 className="text-3xl font-bold text-white">{event?.title}</h1>
          </div>
        </div>
        <CardContent className="p-6 space-y-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
            <div className="flex flex-col space-y-2 sm:space-y-0 sm:space-x-2 sm:flex-row mb-2 sm:mb-0">
              <Badge variant={event.isFree ? "secondary" : "default"} className="bg-purple-100 text-purple-800">
                {event.isFree ? 'Free' : 'Paid'}
              </Badge>
              <Badge variant="outline" className="border-purple-200 text-purple-800">
                {event.isOnline ? 'Online' : 'In-person'}
              </Badge>
            </div>
            <div className="flex space-x-2 w-full sm:w-auto">
              <Button variant="outline" className="flex-1 sm:flex-initial border-purple-200 text-purple-800">
                <EditIcon className="w-4 h-4 mr-2" />
                Edit Event
              </Button>
              <Button 
                variant="outline" 
                className="flex-1 sm:flex-initial border-red-200 text-red-800"
                onClick={handleOpenCancelModal}
              >
                <TrashIcon className="w-4 h-4 mr-2" />
                Cancel Event
              </Button>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              {renderDateInfo()}
              <div className="flex items-center text-gray-600">
                <ClockIcon className="w-6 h-6 mr-2" />
                <span>{event.startTime} - {event.endTime}</span>
              </div>
              <div className="flex items-center text-gray-600">
                {event.isOnline ? (
                  <GlobeIcon className="w-6 h-6 mr-2" />
                ) : (
                  <MapPinIcon className="w-6 h-6 mr-2" />
                )}
                <a 
                  href={event.locationLink} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="hover:underline hover:text-purple-700 transition-colors duration-200"
                >
                  {event.location}
                </a>
              </div>
              <div className="flex items-center text-gray-600">
                <UsersIcon className="w-6 h-6 mr-2" />
                <span>{event.skill}</span>
              </div>
              {!event.isFree && event.price && (
                <div className="flex items-center text-purple-700">
                  <IndianRupeeIcon className="w-6 h-6 mr-2" />
                  <span className="text-lg font-semibold">
                    {typeof event.price === 'number' 
                      ? event.price.toFixed(2) 
                      : parseFloat(event.price) ? parseFloat(event.price).toFixed(2) 
                      : event.price}
                  </span>
                </div>
              )}
            </div>
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-purple-800">Event Statistics</h3>
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <span>Registered Attendees:</span>
                  <span className="font-semibold">{event.attendeeCount} / {event.maxCapacity}</span>
                </div>
                <Progress value={attendeePercentage} className="w-full" />
              </div>
              <Button className="w-full bg-purple-600 hover:bg-purple-700 text-white">
                <BarChart2Icon className="w-4 h-4 mr-2" />
                View Detailed Analytics
              </Button>
            </div>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold text-purple-800 mb-2">Event Description</h3>
            <p className="text-gray-700 leading-relaxed">{event.description}</p>
          </div>
        </CardContent>
        <CardFooter className="bg-purple-50 p-6 flex justify-between items-center">
          <Link to={`/organizer/events/${event.id}/manage-attendees`}>
            <Button className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-full transition-colors duration-200">
              Manage Attendees
            </Button>
          </Link>
          <Link to={`/organizer/events/${event.id}/send-update`}>
            <Button variant="outline" className="border-purple-600 text-purple-600 hover:bg-purple-100 px-6 py-2 rounded-full transition-colors duration-200">
              <Send className="w-4 h-4 mr-2" />
              Send Update to Attendees
            </Button>
          </Link>
        </CardFooter>
      </Card>

      <Dialog open={isCancelModalOpen} onOpenChange={setIsCancelModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cancel Event</DialogTitle>
            <DialogDescription>
              Are you sure you want to cancel this event? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={handleCloseCancelModal}>
              No, keep event
            </Button>
            <Button 
              variant="primary" 
              onClick={handleCancelEvent}
              className="bg-red-600 hover:bg-red-700 text-white"
            >
              Yes, cancel event
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}