import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-transparent py-8">
      <div className="container mx-auto px-4">
        {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4 text-black">EventAtEase</h3>
            <p className="text-sm text-black">Simplifying event management for everyone.</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-black">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="text-sm text-[rgb(124,58,237)] hover:underline">Home</Link></li>
              <li><Link to="/features" className="text-sm text-[rgb(124,58,237)] hover:underline">Features</Link></li>
              <li><Link to="/privacy-policy" className="text-sm text-[rgb(124,58,237)] hover:underline">Privacy Policy</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 text-black">Contact Us</h3>
            <p className="text-sm text-black">Email: support@eventatease.com</p>
            <p className="text-sm text-black">Phone: (123) 456-7890</p>
          </div>
        </div> */}
        <div className="mt-8 pt-8 border-t border-[rgb(124,58,237)] text-center">
          <p className="text-sm text-black">© {currentYear} EventAtEase. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
