import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../Dialog";
import { Button } from "../Button";
import { Switch } from "../Switch";
import { DayPicker, DateRange } from 'react-day-picker';
import { Label } from "../Label";

// Conditionally import the CSS
if (process.env.NODE_ENV !== 'test') {
  require('react-day-picker/dist/style.css');
}

interface DateDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onDateSelect: (dateRange: { start: Date; end: Date }) => void;
}

export const DateDialog: React.FC<DateDialogProps> = ({ isOpen, onOpenChange, onDateSelect }) => {
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [isRangeSelection, setIsRangeSelection] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setDateRange(undefined);
    }
  }, [isOpen]);

  const handleDateSelect = (selectedDate: Date | DateRange | undefined) => {
    if (selectedDate) {
      if (isRangeSelection && 'from' in selectedDate) {
        setDateRange(selectedDate);
      } else if (!isRangeSelection && selectedDate instanceof Date) {
        setDateRange({ from: selectedDate });
      }
    }
  };

  const handleApply = () => {
    if (dateRange?.from) {
      onDateSelect({
        start: dateRange.from,
        end: isRangeSelection && dateRange.to ? dateRange.to : dateRange.from
      });
      onOpenChange(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <div className="sm:max-w-[400px] p-6 bg-white rounded-lg shadow-lg">
        <DialogContent>
          <DialogHeader>
            <div className="text-xl font-semibold text-center">
              <DialogTitle>
                Select Date{isRangeSelection ? ' Range' : ''}
              </DialogTitle>
            </div>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="flex items-center justify-center space-x-2">
              <Switch
                checked={isRangeSelection}
                onCheckedChange={(checked) => {
                  setIsRangeSelection(checked);
                  setDateRange(undefined);
                }}
              />
              <Label htmlFor="range-mode">Date Range</Label>
            </div>
            <div className="flex justify-center">
              {isRangeSelection ? (
                <DayPicker
                  mode="range"
                  selected={dateRange}
                  onSelect={handleDateSelect as (range: DateRange | undefined) => void}
                  numberOfMonths={1}
                  className="border rounded p-2"
                />
              ) : (
                <DayPicker
                  mode="single"
                  selected={dateRange?.from}
                  onSelect={handleDateSelect as (date: Date | undefined) => void}
                  numberOfMonths={1}
                  className="border rounded p-2"
                />
              )}
            </div>
          </div>
          <div className="sm:justify-center">
            <DialogFooter>
              <Button 
                onClick={handleApply} 
                disabled={!dateRange?.from}
                className="w-full sm:w-auto"
              >
                Apply
              </Button>
            </DialogFooter>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};