import React from 'react';
import { Button } from "./ui/Button";
import { Label } from "./ui/Label";
import { Event } from '../types/Event';

interface EventDurationSelectionProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventDurationSelection: React.FC<EventDurationSelectionProps> = ({ event, onChange }) => {
  return (
    <div className="space-y-2">
      <Label>Event Duration</Label>
      <div className="flex items-center space-x-2">
        <Button
          type="button"
          variant={event.isSingleDay ? "default" : "outline"}
          onClick={() => onChange('isSingleDay', true)}
          className={event.isSingleDay ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
        >
          Single Day
        </Button>
        <Button
          type="button"
          variant={!event.isSingleDay ? "default" : "outline"}
          onClick={() => onChange('isSingleDay', false)}
          className={!event.isSingleDay ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
        >
          Multi Day
        </Button>
      </div>
    </div>
  );
};