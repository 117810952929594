import React, { useState, useRef } from 'react';
import { Label } from "./ui/Label";
import { Button } from "./ui/Button";
import { Upload, Wand2 } from 'lucide-react';
import { generateImagePrompt, generateEventImage } from '../utils/aiUtils';

interface EventImageUploadProps {
  onUpload: (file: File | string) => void;
  eventTitle: string;
  eventDescription: string;
}

export const EventImageUpload: React.FC<EventImageUploadProps> = ({ onUpload, eventTitle, eventDescription }) => {
  const [preview, setPreview] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationError, setGenerationError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      onUpload(file);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleGenerateAI = async () => {
    setIsGenerating(true);
    setGenerationError(null);
    try {
      const imagePrompt = await generateImagePrompt(eventTitle, eventDescription);
      if (!imagePrompt) {
        throw new Error("Failed to generate image prompt");
      }
      
      const base64Data = await generateEventImage(imagePrompt);
      if (!base64Data) {
        throw new Error("Failed to generate image");
      }
      
      const previewUrl = `data:image/jpeg;base64,${base64Data}`;
      setPreview(previewUrl);
      onUpload(base64Data);
    } catch (error) {
      console.error("Error in AI image generation:", error);
      setGenerationError("Failed to generate image. Please try uploading manually.");
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="space-y-4">
      <Label>Event Image</Label>
      <div className="flex items-center space-x-4">
        <Button type="button" variant="outline" onClick={handleUploadClick}>
          <Upload className="mr-2 h-4 w-4" />
          Upload Image
        </Button>
        <Button type="button" variant="outline" onClick={handleGenerateAI} disabled={isGenerating}>
          <Wand2 className="mr-2 h-4 w-4" />
          {isGenerating ? 'Generating...' : 'Generate with AI'}
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageUpload}
          accept="image/*"
          className="hidden"
        />
      </div>
      {generationError && (
        <p className="text-red-500 mt-2">{generationError}</p>
      )}
      {preview && (
        <div className="mt-4">
          <img src={preview} alt="Preview" className="max-w-full h-auto rounded-lg" />
        </div>
      )}
    </div>
  );
};