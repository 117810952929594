import React from 'react';
import { EventCard } from './EventCard';
import { Event } from '../types/Event';

interface EventsListProps {
  events: Event[];
  linkTo: (eventId: string) => string;
  userId: string;
}

export const EventsList: React.FC<EventsListProps> = ({ events, linkTo, userId }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {events.map((event) => (
        <EventCard 
          key={event.id} 
          event={event} 
          linkTo={linkTo(event.id)}
          userId={userId}
          onBookmarkToggle={() => {}} // Add a dummy function for now
          isBookmarked={false} // Add a dummy value for now
        />
      ))}
    </div>
  );
};