import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Separator } from "../../components/ui/Separator";
import { CheckCircle, Calendar, MapPin, Clock, Info, User, DollarSign } from "lucide-react";
import { registerUserForEvent } from '../../services/eventService';
import { useAuth } from '../../contexts/AuthContext';
import { getUserName } from '../../services/userService';
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/Alert";

interface EventState {
  eventTitle: string;
  eventDate: string;
  eventTime: string;
  eventLocation: string;
  eventDescription: string;
  organizer: string;
  isFree: boolean;
  price: number;
  eventImage: string; // Assuming you have this in your event state
  attendeeCount: number; // Assuming you have this in your event state
}

export const RegistrationScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, customUser } = useAuth(); // You can access both Firebase user and custom user data
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const event = location.state as EventState;
  const [organizerName, setOrganizerName] = useState<string>('');

  useEffect(() => {
    const fetchOrganizerName = async () => {
      if (event && event.organizer) {
        try {
          const name = await getUserName(event.organizer);
          setOrganizerName(name);
        } catch (error) {
          console.error('Error fetching organizer name:', error);
        }
      }
    };

    fetchOrganizerName();
  }, [event]);

  if (!event) {
    return <div>Error: Event details not found</div>;
  }

  const handleRegister = async () => {
    if (!user || !id) {
      setError('You must be logged in to register for an event.');
      return;
    }

    setIsRegistering(true);
    setError(null);
    setSuccess(null);

    try {
      await registerUserForEvent(id, user.uid);
      setSuccess("You're all set! We're excited to see you at the event.");
      setTimeout(() => {
        navigate('/registration-confirmation', { 
          state: { 
            eventTitle: event.eventTitle,
            eventDate: event.eventDate,
            eventImage: event.eventImage,
            attendeeCount: event.attendeeCount + 1,
            eventId: id
          } 
        });
      }, 2000);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsRegistering(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-2xl">
      <Card className="w-full">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center text-purple-800">Registration Details</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          {error && (
            <Alert variant="destructive">
              <AlertTitle>Registration Failed</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          {success && (
            <Alert variant="default">
              <AlertTitle>Registration Successful</AlertTitle>
              <AlertDescription>{success}</AlertDescription>
            </Alert>
          )}
          <div>
            <h2 className="text-xl font-semibold text-purple-700 mb-2">{event.eventTitle}</h2>
            <p className="text-gray-600 text-sm">{event.eventDescription}</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2 text-gray-600">
              <div className="flex items-center">
                <Calendar className="w-5 h-5 mr-2 text-purple-600" />
                <span>{event.eventDate}</span>
              </div>
              {event.eventTime && (
                <div className="flex items-center">
                  <Clock className="w-5 h-5 mr-2 text-purple-600" />
                  <span>{event.eventTime}</span>
                </div>
              )}
              <div className="flex items-center">
                <MapPin className="w-5 h-5 mr-2 text-purple-600" />
                <span>{event.eventLocation}</span>
              </div>
            </div>
            <div className="space-y-2 text-gray-600">
              <div className="flex items-center">
                <User className="w-5 h-5 mr-2 text-purple-600" />
                <span>Organized by: {organizerName || 'Loading...'}</span>
              </div>
              <div className="flex items-center">
                <DollarSign className="w-5 h-5 mr-2 text-purple-600" />
                <span>{event.isFree ? 'Free Event' : `Price: $${event.price.toFixed(2)}`}</span>
              </div>
            </div>
          </div>
          <Separator />
          <div className="bg-purple-50 p-4 rounded-lg">
            <div className="flex items-center mb-2">
              <Info className="w-5 h-5 mr-2 text-purple-600" />
              <span className="font-semibold text-purple-800">Registration Summary</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-lg">Total Amount:</span>
              <span className="text-2xl font-bold text-purple-800">
                ${event.isFree ? '0.00' : event.price.toFixed(2)}
              </span>
            </div>
            {event.isFree && (
              <div className="flex items-center text-green-600 mt-2">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>This is a free event</span>
              </div>
            )}
          </div>
        </CardContent>
        <CardFooter className="flex flex-col items-center">
          <Button 
            className="w-full bg-purple-600 hover:bg-purple-700 text-white py-6 text-lg"
            onClick={handleRegister}
            disabled={isRegistering || !!success}
          >
            {isRegistering 
              ? 'Processing...' 
              : success
                ? 'Registration Complete'
                : event.isFree 
                  ? 'Complete Registration' 
                  : 'Pay and Register'}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};
