import React, { useState } from 'react';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import { Card, CardContent } from '../components/ui/Card';
import { MapPinIcon, PhoneIcon, MailIcon, SendIcon } from 'lucide-react';

const ContactPage: React.FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', { firstName, lastName, email, phone, message });
    // Reset form fields
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setMessage('');
  };

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1">
        <section className="w-full py-20 md:py-32 bg-purple-100 text-[#3498db]">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl mb-6">
              Get in Touch
            </h1>
            <p className="mx-auto max-w-[700px] text-xl mb-10 text-gray-600">
              We're here to help. Reach out to us with any questions or concerns about EventAtEase.
            </p>
          </div>
        </section>

        <section className="w-full py-20 bg-white">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="grid gap-10 md:grid-cols-2">
              <Card className="bg-purple-50 shadow-lg hover:shadow-xl transition-shadow duration-300">
                <CardContent className="p-8">
                  <h2 className="text-2xl font-semibold mb-6 text-[rgb(124,58,237)]">Contact Information</h2>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <MapPinIcon className="h-6 w-6 text-[rgb(124,58,237)] mr-3" />
                      <span>123 Event Street, City, Country</span>
                    </div>
                    <div className="flex items-center">
                      <PhoneIcon className="h-6 w-6 text-[rgb(124,58,237)] mr-3" />
                      <span>+1 (123) 456-7890</span>
                    </div>
                    <div className="flex items-center">
                      <MailIcon className="h-6 w-6 text-[rgb(124,58,237)] mr-3" />
                      <span>contact@eventatease.com</span>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
                <CardContent className="p-8">
                  <h2 className="text-2xl font-semibold mb-6 text-[rgb(124,58,237)]">Send Us a Message</h2>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <Input
                        placeholder="First Name *"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                      <Input
                        placeholder="Last Name *"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                    <Input
                      type="email"
                      placeholder="Your Email *"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <Input
                      type="tel"
                      placeholder="Your Phone Number (Optional)"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <textarea
                      className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                      rows={4}
                      placeholder="Your Message *"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                    <p className="text-sm text-gray-500">* Required fields</p>
                    <Button type="submit" variant="primary" className="w-full">
                      Send Message <SendIcon className="ml-2 h-5 w-5" />
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ContactPage;
