import React from 'react';
import { Button } from "../Button";
import { AlertCircle } from 'lucide-react';

interface FilterBadgeProps {
  label: string;
  value: string;
  onClick: () => void;
  onClear: () => void;
  isActive: boolean;
  loading?: boolean;
  error?: string | null;
}

export const FilterBadge: React.FC<FilterBadgeProps> = ({ 
  label, 
  value, 
  isActive, 
  onClick, 
  onClear, 
  loading,
  error
}) => {
  return (
    <div className="relative">
      <Button
        onClick={onClick}
        variant={isActive ? "primary" : "outline"}
        className={`px-3 py-1 text-sm ${isActive ? 'bg-purple-600 text-white' : 'bg-white text-gray-700'} ${error ? 'border-red-500' : ''}`}
        disabled={loading}
      >
        {label}
        {value && `: ${value}`}
        {loading && <span className="ml-2">Loading...</span>}
        {error && <AlertCircle className="w-4 h-4 ml-2 text-red-500" />}
      </Button>
      {isActive && onClear && (
        <button
          onClick={onClear}
          className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs"
          aria-label={`Clear ${label} filter`}
          disabled={loading}
        >
          ×
        </button>
      )}
    </div>
  );
};