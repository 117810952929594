import React, { useState, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "../components/ui/Card"
import { Button } from "../components/ui/Button"
import { Input } from "../components/ui/Input"
import { Label } from "../components/ui/Label"
import { UserIcon, PhoneIcon, CalendarIcon } from "lucide-react"
import { useAuth } from '../contexts/AuthContext';
import { db as firestore } from '../config/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { User } from '../types/User';

export const FirstSignInScreen: React.FC = () => {
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')
  const [gender, setGender] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')
  const [otp, setOtp] = useState('')
  const [otpSent, setOtpSent] = useState(false)
  const [otpVerified, setOtpVerified] = useState(false)
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { user, setIsFirstTimeUser } = useAuth();
  const navigate = useNavigate();

  const handleSendOtp = () => {
    // Implement OTP sending logic
    console.log('Sending OTP to', mobile)
    setOtpSent(true)
  }

  const handleVerifyOtp = () => {
    // Implement OTP verification logic
    console.log('Verifying OTP', otp)
    setOtpVerified(true)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!user) return;

    setIsLoading(true);
    setError(null);

    try {
      const newUser: Partial<User> = {
        uid: user.uid,
        email: user.email || '',
        displayName: user.displayName || name,
        gender,
        dateOfBirth: new Date(dateOfBirth),
        phone: mobile,
        eventsAttended: [],
        eventsCreated: [],
        bankAccountDetails: {
          accountNumber: '',
          bankName: '',
          ifscCode: '',
          isVerified: false, // Add this line
        },
        upiDetails: '',
        upiVerified: false, // Add this line if it's missing in your User type
        subscribers: [],
        isOrganizer: false,
        profilePicture: user.photoURL || '',
        createdAt: new Date(),
        lastLogin: new Date(),
      };

      console.log('Attempting to write user data:', newUser);
      console.log('User UID:', user.uid);

      // Persist user details to Firestore
      await setDoc(doc(firestore, 'users', user.uid), newUser);

      console.log('User data successfully written');

      // Update isFirstTimeUser state
      setIsFirstTimeUser(false);

      // Navigate to the home page
      console.log('Attempting to navigate to /attendee/home');
      navigate('/attendee/home', { replace: true });

    } catch (error) {
      console.error('Error updating user profile:', error);
      if (error instanceof Error) {
        setError(`Failed to create account: ${error.message}`);
      } else {
        setError('Failed to create account. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-md">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-purple-800 text-center">Welcome to EventAtEase</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name">Full Name</Label>
              <div className="relative">
                <UserIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <Input
                  id="name"
                  placeholder="Enter your full name"
                  value={name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                  className="pl-10"
                  required
                />
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="mobile">Mobile Number</Label>
              <div className="relative">
                <PhoneIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <Input
                  id="mobile"
                  placeholder="Enter your mobile number"
                  value={mobile}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setMobile(e.target.value)}
                  className="pl-10"
                  required
                  disabled={otpVerified}
                />
              </div>
            </div>

            {!otpVerified && (
              <div className="space-y-2">
                {!otpSent ? (
                  <Button
                    type="button"
                    onClick={handleSendOtp}
                    className="w-full"
                  >
                    Send OTP
                  </Button>
                ) : (
                  <>
                    <Label htmlFor="otp">OTP</Label>
                    <div className="flex space-x-2">
                      <Input
                        id="otp"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setOtp(e.target.value)}
                        required
                      />
                      <Button
                        type="button"
                        onClick={handleVerifyOtp}
                      >
                        Verify
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}

            <div className="space-y-2">
              <Label htmlFor="gender">Gender</Label>
              <select
                id="gender"
                value={gender}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setGender(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                required
              >
                <option value="">Select your gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="dateOfBirth">Date of Birth</Label>
              <div className="relative">
                <CalendarIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <Input
                  id="dateOfBirth"
                  type="date"
                  value={dateOfBirth}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setDateOfBirth(e.target.value)}
                  className="pl-10"
                  required
                />
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter>
          <Button
            type="submit"
            className="w-full"
            disabled={!otpVerified || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? 'Creating Account...' : 'Create Account'}
          </Button>
        </CardFooter>
        {error && <p className="text-red-500 text-center mt-2">{error}</p>}
      </Card>
    </div>
  )
}
