import React from 'react';
import { Card, CardContent } from "../../../components/ui/Card";
import { MapPinIcon, GlobeIcon } from "../../../utils/icons";
import { Event } from '../../../models/Event';

interface EventLocationInfoProps {
  event: Event;
}

export const EventLocationInfo: React.FC<EventLocationInfoProps> = ({ event }) => {
  const cardContent = event.isOnline ? (
    <>
      <div className="flex items-center mb-2">
        <GlobeIcon className="w-6 h-6 text-purple-600 mr-2" />
        <h3 className="text-lg font-semibold text-purple-800">Online Event</h3>
      </div>
      <p className="text-sm text-gray-600">Join from anywhere</p>
    </>
  ) : (
    <>
      <div className="flex items-center mb-2">
        <MapPinIcon className="w-6 h-6 text-purple-600 mr-2" />
        <h3 className="text-lg font-semibold text-purple-800">Location</h3>
      </div>
      <p className="text-sm text-gray-600">{event.location}</p>
    </>
  );

  const card = (
    <Card className="h-full bg-gradient-to-br from-purple-50 to-purple-100">
      <CardContent className="p-4">{cardContent}</CardContent>
    </Card>
  );

  const linkWrapper = (content: React.ReactNode, link: string) => (
    <a 
      href={link} 
      target="_blank" 
      rel="noopener noreferrer"
      className="block h-full transition-transform hover:scale-105"
    >
      {content}
    </a>
  );

  if (event.isOnline && event.meetingLink) {
    return linkWrapper(card, event.meetingLink);
  } else if (!event.isOnline && event.locationLink) {
    return linkWrapper(card, event.locationLink);
  } else {
    return card;
  }
};