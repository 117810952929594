import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'outline' | 'default';
  as?: React.ElementType;
  to?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  as: Component = 'button',
  className = '',
  icon,
  to,
  ...props
}) => {
  const baseClasses = 'px-4 py-2 rounded-md font-semibold transition-colors duration-200 flex items-center justify-center';
  const variantClasses = 
  variant === 'primary' ? 'bg-purple-500 text-white hover:bg-purple-600' :
  variant === 'default' ? 'bg-purple-500 text-white hover:bg-purple-600' :
  'bg-white text-purple-500 border border-purple-500 hover:bg-purple-50';

  if (to) {
    return (
      <Link
        to={to}
        className={`${baseClasses} ${variantClasses} ${className}`}
        {...(props as Omit<LinkProps, 'to'>)}
      >
        {icon && <span className="mr-2">{icon}</span>}
        {children}
      </Link>
    );
  }

  return (
    <Component
      className={`${baseClasses} ${variantClasses} ${className}`}
      {...props}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {children}
    </Component>
  );
};
