import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../ui/Button';
import { CalendarIcon } from '../../utils/icons';

export const LandingHeader: React.FC = () => (
  <header className="bg-white shadow-sm">
    <div className="container mx-auto px-4 py-4 flex flex-col sm:flex-row justify-between items-center">
      <Link to="/" className="flex items-center space-x-2">
        <CalendarIcon className="h-8 w-8 text-purple-500" />
        <span className="text-2xl font-bold text-purple-500">EventAtEase</span>
      </Link>
      <div className="flex flex-col sm:flex-row items-center mt-4 sm:mt-0">
        <nav className="flex flex-wrap justify-center gap-4 sm:gap-6 mb-4 sm:mb-0">
          <Link to="/features" className="text-base font-semibold text-gray-700 hover:text-purple-500 hover:underline underline-offset-4">
            Features
          </Link>
          <Link to="/how-it-works" className="text-base font-semibold text-gray-700 hover:text-purple-500 hover:underline underline-offset-4">
            How It Works
          </Link>
          <Link to="/pricing" className="text-base font-semibold text-gray-700 hover:text-purple-500 hover:underline underline-offset-4">
            Pricing
          </Link>
          <Link to="/contact" className="text-base font-semibold text-gray-700 hover:text-purple-500 hover:underline underline-offset-4">
            Contact
          </Link>
        </nav>
        <div className="flex items-center space-x-4 sm:ml-6">
          <Button as={Link} to="/login" variant="outline" className="px-3 py-2 text-sm sm:px-4 sm:py-2 sm:text-base">
            Login
          </Button>
          <Button as={Link} to="/signup" className="px-3 py-2 text-sm sm:px-4 sm:py-2 sm:text-base">
            Sign Up
          </Button>
        </div>
      </div>
    </div>
  </header>
);
