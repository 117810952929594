import React from 'react';

interface EventHeaderProps {
  title: string;
  imageUrl: string;
  onImageError: () => void;
}

export const EventHeader: React.FC<EventHeaderProps> = ({ title, imageUrl, onImageError }) => {
  return (
    <div className="relative">
      <img
        src={imageUrl}
        alt={title}
        className="w-full h-64 object-cover"
        onError={onImageError}
      />
      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-purple-900 to-transparent p-6">
        <h1 className="text-3xl font-bold text-white">{title}</h1>
      </div>
    </div>
  );
};