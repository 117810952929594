import { BaseModel } from './BaseModel';
import { 
  Timestamp, 
  setDoc, 
  doc, 
  query, 
  where, 
  getDocs, 
  QueryDocumentSnapshot 
} from 'firebase/firestore';
import { User as UserType } from '../types/User';
import { User as FirebaseUser } from 'firebase/auth';

export class User extends BaseModel implements Omit<UserType, keyof FirebaseUser> {
  static collectionName = 'users';

  constructor(
    public id: string,
    public gender: string,
    public dateOfBirth: Date,
    public eventsAttended: string[],
    public eventsCreated: string[],
    public bankAccountDetails: {
      accountNumber: string;
      bankName: string;
      ifscCode: string;
      isVerified: boolean;
    },
    public upiDetails: string,
    public upiVerified: boolean,
    public subscribers: string[],
    public isOrganizer: boolean,
    public profilePicture: string,
    public phone: string,
    public createdAt: Date,
    public lastLogin: Date,
    public bookmarkedEvents: string[]
  ) {
    super(id);
  }

  // Create a new user
  static async create(userData: Omit<UserType, 'id' | keyof FirebaseUser>): Promise<User> {
    const docRef = doc(User.getCollectionRef());
    await setDoc(docRef, { ...userData, createdAt: new Date() });
    return new User(
      docRef.id,
      userData.gender,
      userData.dateOfBirth,
      userData.eventsAttended,
      userData.eventsCreated,
      userData.bankAccountDetails,
      userData.upiDetails,
      userData.upiVerified,
      userData.subscribers,
      userData.isOrganizer,
      userData.profilePicture,
      userData.phone,
      userData.createdAt,
      userData.lastLogin,
      userData.bookmarkedEvents
    );
  }

  // Find a user by email
  static async findByEmail(email: string): Promise<User | null> {
    const q = query(this.getCollectionRef(), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return null;
    const doc = querySnapshot.docs[0];
    const data = doc.data() as UserType;
    return new User(
      doc.id,
      data.gender,
      data.dateOfBirth instanceof Timestamp ? data.dateOfBirth.toDate() : new Date(data.dateOfBirth),
      data.eventsAttended,
      data.eventsCreated,
      data.bankAccountDetails,
      data.upiDetails,
      data.upiVerified,
      data.subscribers,
      data.isOrganizer,
      data.profilePicture,
      data.phone,
      data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(data.createdAt),
      data.lastLogin instanceof Timestamp ? data.lastLogin.toDate() : new Date(data.lastLogin),
      data.bookmarkedEvents
    );
  }

  // Convert to JSON
  public toJSON(): Omit<UserType, keyof FirebaseUser> {
    return {
      gender: this.gender,
      dateOfBirth: this.dateOfBirth,
      eventsAttended: this.eventsAttended,
      eventsCreated: this.eventsCreated,
      bankAccountDetails: this.bankAccountDetails,
      upiDetails: this.upiDetails,
      upiVerified: this.upiVerified,
      subscribers: this.subscribers,
      isOrganizer: this.isOrganizer,
      profilePicture: this.profilePicture,
      phone: this.phone,
      createdAt: this.createdAt,
      lastLogin: this.lastLogin,
      bookmarkedEvents: this.bookmarkedEvents
    };
  }

  // Save or update user data
  async save(): Promise<void> {
    const docRef = doc(User.getCollectionRef(), this.id);
    const data = this.toJSON();
    // Remove undefined fields
    Object.keys(data).forEach(key => (data as any)[key] === undefined && delete (data as any)[key]);
    await setDoc(docRef, data, { merge: true });
  }

  // Add methods to handle bookmarking
  async toggleBookmark(eventId: string): Promise<void> {
    const index = this.bookmarkedEvents.indexOf(eventId);
    if (index > -1) {
      this.bookmarkedEvents.splice(index, 1);
    } else {
      this.bookmarkedEvents.push(eventId);
    }
    await this.save();
  }

  isEventBookmarked(eventId: string): boolean {
    return this.bookmarkedEvents.includes(eventId);
  }
}
